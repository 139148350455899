import { __decorate } from "tslib";
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { atividadeStore, cursoStore, feriadoStore, livroStore, mainStore, salaStore, turmaAtividadeStore, turmaStore, userActionStore, userStore, userToolStore, } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newTurma } from '@/interfaces/turma';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import FichaFrequenciaComponent from './FichaFrequenciaComponent.vue';
import GestaoComponent from './GestaoComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import TurmaAlunoAtividadeLogComponent from '@/components/TurmaAlunoAtividadeLogComponent.vue';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
import TurmaCancelarComponent from '@/components/TurmaCancelarComponent.vue';
import JaasMeet from '@/components/JaasMeet.vue';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('timeValidationRules', {
    validate: (value) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(value);
    },
    getMessage: (field) => 'O campo de hora deve estar no formato HH:MM',
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: '', value: 'idx', width: '10px' },
            { text: 'Data', value: 'data_atividade' },
            { text: 'Hora', value: 'hora_inicio_atividade' },
            { text: 'Aula Planejada para turma', value: 'atividade' },
            { text: 'Aula Prevista pelo Cronograma', value: 'atividade_prevista' },
            { text: '', value: 'actions' },
        ];
        this.feriadoHeaders = [
            { text: 'Nome', value: 'nome' },
            { text: 'Data', value: 'data' },
            { text: 'Tipo', value: 'tipo' },
        ];
        this.value = 'geral';
        this.idiomaSelected = [];
        this.cursoSelected = [];
        this.livroSelected = [];
        this.professorSelected = null;
        this.publicoAlvoSelected = [];
        this.previsao_inicio = null;
        this.previsao_termino = null;
        this.cronogramaPersonalizacao = {};
        this.id = null;
        this.tab = 0;
        this.curso_id = null;
        this.dateEdit = [];
        this.tempData = [];
        // public cronograma_conteudo = [];
        this.cronograma_horario = [];
        this.loadingDataTable = false;
        this.turmaAtividadeItems = [];
        this.entityObject = null;
        this.saveLoading = false;
        this.professorOptions = null;
        this.selectedFichaFrequenciaAlunos = [];
        this.requisicaoReforcoObservacao = '';
        this.selectedReforcoLivro = {};
        this.selectedReforcoAtividade = null;
        this.dialog = false;
        this.rodandoMounted = false;
        this.livroOptions = [];
        this.items = [];
        this.curso = {};
        this.creating = false;
        this.cursoModalidadeSelected = [];
        this.fichaFrequenciaItems = [];
        this.livroAtividadesOptions = [];
        this.livroAtividadeSelected = null;
        this.horaInicioAtividadeUpdated = null;
        this.periodoFeriados = [];
        this.alunosTurma = [];
        this.isMobileTurma = false;
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Turma) {
            return userToolStore.userToolsIndexed.Turma[0].allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActionsEditarCronogramaAllow() {
        return userActionStore.editarCronogramaAllow;
    }
    get userActionsCancelarTurmaAllow() {
        return userActionStore.cancelarTurmaAllow;
    }
    get userActionsCancelarTurmaRequestPermission() {
        return userActionStore.cancelarTurmaRequestPermission;
    }
    get userActionsCancelarTurmaObject() {
        return userActionStore.cancelarTurmaObject;
    }
    get livrosVigentes() {
        return livroStore.livrosVigentes;
    }
    get livros() {
        return livroStore.livros;
    }
    get cursoOptions() {
        return _.filter(cursoStore.cursos, (item) => item.idioma == this.idiomaSelected);
    }
    get salasOptions() {
        return salaStore.salas;
    }
    get isBeingEdited() {
        return this.dateEdit.includes(true);
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    getLivroSubtitle(livro_nome) {
        if (livro_nome == 'LIVRO KIDS 1') {
            return 'Turma inicial para não alfabetizados';
        }
        else if (livro_nome == 'LIVRO KIDS 2') {
            return 'Turma inicial para alfabetizados';
        }
    }
    showLogTurma(ref, id) {
        const form = ref;
        form.show(id);
    }
    showLogAtividades(ref) {
        const form = ref;
        form.show();
    }
    async ajustarTurma() {
        this.$swal({
            title: 'Confirma o ajuste do cronograma?',
            text: 'Utilize essa funcionalidade caso a ficha de frequência possua alguma anormalidade',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                await turmaStore.ajustarTurma(this.id);
                window.location.reload(true);
            }
        });
    }
    async excluirTurma() {
        this.$swal({
            title: 'Confirma a exclusão da turma?',
            text: 'Só utilize a exclusão de turmas quando a mesma foi criada de forma errada ou por engano.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                const resultado = await turmaStore.deleteTurma(this.id);
                if (resultado === '') {
                    this.$router.back();
                }
            }
        });
    }
    async onClickCancelarTurma() {
        const cancelarComponent = this.$refs.cancelarComponent;
        cancelarComponent.show();
    }
    async confirmReplanejar(item, data) {
        this.$swal({
            title: 'Deseja realmente salvar e replanejar?',
            text: 'Essa ação atualizará as atividades planejadas.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.replanejar(item, data);
                await this.submit(false);
            }
        });
    }
    async replanejar(item, data) {
        const index = this.turmaAtividadeItems.indexOf(item);
        item.hora_inicio_atividade = this.horaInicioAtividadeUpdated;
        const day = new Date(item.data_atividade).getDay() + 1 === 7
            ? 0
            : new Date(item.data_atividade).getDay() + 1;
        const cronograma = _.find(this.cronograma_horario, (val) => val.dia_semana_index === day);
        const cronIdx = _.findIndex(this.cronograma_horario, cronograma);
        const temp = [...this.cronograma_horario];
        let horario = '';
        let aulaNum = index % this.entityObject.quantidade_aula_por_dia;
        if (aulaNum === 0) {
            horario = this.horaInicioAtividadeUpdated;
        }
        else {
            const tempHorario = this.horaInicioAtividadeUpdated.split(':');
            horario =
                +tempHorario[0] - aulaNum >= 10
                    ? `${+tempHorario[0] - aulaNum}:${tempHorario[1]}`
                    : `0${+tempHorario[0] - aulaNum}:${tempHorario[1]}`;
        }
        temp[cronIdx] = {
            ...this.cronograma_horario[cronIdx],
            horario,
        };
        this.cronograma_horario = [...temp];
        const access = 'edit_date_' + item.id;
        if (!(await this.$refs[access].$refs.observer.validate())) {
            return;
        }
        const indexAtividade = _.findIndex(this.livroAtividadesOptions, (val) => val.id === this.livroAtividadeSelected);
        const atividadesRestantes = this.livroAtividadesOptions.slice(indexAtividade);
        const turmaAtividadeNMexe = [...this.turmaAtividadeItems];
        turmaAtividadeNMexe.splice(index);
        let turmaAtividadesMexe = this.turmaAtividadeItems.slice(index);
        if (atividadesRestantes.length <= turmaAtividadesMexe.length) {
            for (let i = 0; i < turmaAtividadesMexe.length; i++) {
                if (i >= atividadesRestantes.length) {
                    turmaAtividadesMexe[i] = {
                        ...turmaAtividadesMexe[i],
                        atividade: null,
                        atividade_id: null,
                    };
                    continue;
                }
                turmaAtividadesMexe[i] = {
                    ...turmaAtividadesMexe[i],
                    atividade: atividadesRestantes[i].descricao,
                    atividade_id: atividadesRestantes[i].id,
                };
            }
            turmaAtividadesMexe = _.filter(turmaAtividadesMexe, (val) => !(val.atividade_id == null && val.isIncremento));
            this.turmaAtividadeItems = [
                ...turmaAtividadeNMexe.concat(...turmaAtividadesMexe),
            ];
        }
        else {
            for (let i = 0; i < atividadesRestantes.length; i++) {
                if (i < turmaAtividadesMexe.length) {
                    const val = turmaAtividadesMexe[i];
                    turmaAtividadesMexe[i] = {
                        ...turmaAtividadesMexe[i],
                        atividade: atividadesRestantes[i].descricao,
                        atividade_id: atividadesRestantes[i].id,
                    };
                }
                else {
                    const obj = {
                        ...turmaAtividadesMexe[turmaAtividadesMexe.length - 1],
                        atividade: atividadesRestantes[i].descricao,
                        atividade_id: atividadesRestantes[i].id,
                        atividade_prevista: null,
                        atividade_prevista_id: null,
                        isIncremento: true,
                        id: null,
                    };
                    turmaAtividadesMexe.push(obj);
                }
            }
            this.turmaAtividadeItems = [
                ...turmaAtividadeNMexe.concat(...turmaAtividadesMexe),
            ];
        }
        const dia = new Date(item.data_atividade);
        const tempDia = new Date(data);
        dia.setUTCHours(3);
        let dia_semana = dia.getDay();
        let tempDiaSemana = tempDia.getDay();
        if (tempDiaSemana === 6) {
            tempDiaSemana = 0;
        }
        else {
            tempDiaSemana = tempDiaSemana + 1;
        }
        let days = _.map(
        // @ts-ignore
        this.cronograma_horario, 'dia_semana_index');
        if (days.indexOf(dia_semana) === -1) {
            dia_semana = days[0];
        }
        this.cronograma_horario[days.indexOf(dia_semana)].dia_semana_index =
            tempDiaSemana;
        // @ts-ignore
        this.cronograma_horario[days.indexOf(dia_semana)].dia_semana =
            // @ts-ignore
            this.diasDaSemana()[tempDiaSemana];
        days = _.map(
        // @ts-ignore
        this.cronograma_horario, 'dia_semana_index');
        let dayIndex = 0;
        if (days.length > 1) {
            dayIndex = days.indexOf(dia_semana);
        }
        let aulaAtual = 1 + (index % this.entityObject.quantidade_aula_por_dia);
        this.turmaAtividadeItems[index].data_atividade = new Date(data);
        if (index === 0) {
            this.previsao_inicio = data;
        }
        if (dayIndex <= days.length - 2) {
            dayIndex++;
        }
        else {
            dayIndex = 0;
        }
        this.turmaAtividadeItems[index].data_atividade = this.turmaAtividadeItems[index].data_atividade
            .toISOString()
            .split('T')[0];
        for (let i = index + 1; i < this.turmaAtividadeItems.length; i++) {
            if (aulaAtual / this.entityObject.quantidade_aula_por_dia < 1) {
                aulaAtual = aulaAtual + 1;
                this.turmaAtividadeItems[i].data_atividade =
                    this.turmaAtividadeItems[i - 1].data_atividade;
            }
            else {
                aulaAtual = 1;
                this.turmaAtividadeItems[i].data_atividade = this.nextWeekdayDate(this.turmaAtividadeItems[i - 1].data_atividade, days[dayIndex]);
                this.turmaAtividadeItems[i].data_atividade = this.turmaAtividadeItems[i].data_atividade
                    .toISOString()
                    .split('T')[0];
                if (dayIndex <= days.length - 2) {
                    dayIndex++;
                }
                else {
                    dayIndex = 0;
                }
            }
            // Mexe com os horários das aulas
            let indice = new Date(this.turmaAtividadeItems[i].data_atividade).getDay() + 1;
            if (indice === 7) {
                indice = 0;
            }
            const horaInicioAtividade = _.find(this.cronograma_horario, (c) => c.dia_semana_index == indice).horario;
            aulaNum = i % this.entityObject.quantidade_aula_por_dia;
            const horaInicioAtividadeSplit = horaInicioAtividade.split(':');
            this.turmaAtividadeItems[i].hora_inicio_atividade =
                aulaNum === 0
                    ? horaInicioAtividade
                    : +horaInicioAtividadeSplit[0] + aulaNum >= 10
                        ? `${+horaInicioAtividadeSplit[0] + aulaNum}:${horaInicioAtividadeSplit[1]}`
                        : `0${+horaInicioAtividadeSplit[0] + aulaNum}:${horaInicioAtividadeSplit[1]}`;
        }
        // Parte do indice alterado para corrigir as datas
        const tempArr = this.turmaAtividadeItems.slice(index, this.turmaAtividadeItems.length);
        if (this.turmaAtividadeItems[index - 1]?.data_atividade !==
            this.turmaAtividadeItems[index].data_atividade) {
            for (let i = 0; i < tempArr.length; i++) {
                if (i % this.entityObject.quantidade_aula_por_dia === 0) {
                    data = tempArr[i].data_atividade;
                }
                tempArr[i].data_atividade = data;
            }
        }
        this.turmaAtividadeItems.splice(index, this.turmaAtividadeItems.length);
        this.turmaAtividadeItems = [...this.turmaAtividadeItems.concat(...tempArr)];
        this.entityObject.previsao_termino = [...this.turmaAtividadeItems]
            .reverse()
            .find((x) => !!x.atividade).data_atividade;
        this.previsao_termino = this.entityObject.previsao_termino;
        const dateEditIndex = _.findIndex(this.dateEdit, (val) => val);
        this.dateEdit[dateEditIndex] = false;
        this.dateEdit = [...this.dateEdit];
    }
    onChangeTurmaAtividadeItems(val) {
        if (val) {
            if (val.length > 0) {
                this.previsao_termino = [...val]
                    .reverse()
                    .find((x) => !!x.atividade).data_atividade;
            }
        }
    }
    async toggleEdit(item, ultima_atividade) {
        this.loadingDataTable = true;
        if (this.dateEdit[this.turmaAtividadeItems.indexOf(item)]) {
            const access = 'edit_date_' + item.id;
            if (!(await this.$refs[access].$refs.observer.validate())) {
                this.loadingDataTable = false;
                return;
            }
            this.dateEdit[this.turmaAtividadeItems.indexOf(item)] = false;
            item.data_atividade =
                this.tempData[this.turmaAtividadeItems.indexOf(item)];
            const index = this.turmaAtividadeItems.indexOf(item);
            const atv = _.find(this.livroAtividadesOptions, (val) => val.id === this.livroAtividadeSelected);
            if (atv) {
                this.turmaAtividadeItems[index].atividade_id = atv.id;
                this.turmaAtividadeItems[index].atividade = atv.descricao;
            }
            else {
                this.turmaAtividadeItems[index].atividade_id = null;
                this.turmaAtividadeItems[index].atividade = '';
            }
            if (ultima_atividade) {
                this.entityObject.previsao_termino = item.data_atividade;
                this.previsao_termino = item.data_atividade;
                const result = await turmaStore.updateTurma(this.entityObject);
            }
            if (this.id) {
                await turmaStore.updateCronograma({
                    data: this.turmaAtividadeItems,
                    id: this.id,
                });
                const payload = {
                    id: item.id,
                    atividade_id: this.turmaAtividadeItems[index].atividade_id,
                    atividade: this.turmaAtividadeItems[index].atividade,
                    turma_id: item.turma_id,
                    hora_inicio_atividade: this.horaInicioAtividadeUpdated,
                };
                await turmaAtividadeStore.UpdateTurmaAtividade(payload);
                this.turmaAtividadeItems = await turmaStore.getCronograma(this.id);
            }
            this.tempData[this.turmaAtividadeItems.indexOf(item)] = null;
        }
        else {
            this.livroAtividadeSelected = item.atividade_id;
            this.horaInicioAtividadeUpdated = item.hora_inicio_atividade;
            this.dateEdit[this.turmaAtividadeItems.indexOf(item)] = true;
            this.tempData[this.turmaAtividadeItems.indexOf(item)] =
                item.data_atividade;
        }
        this.dateEdit = [...this.dateEdit];
        this.loadingDataTable = false;
    }
    async cancelEdit(item) {
        this.livroAtividadeSelected = null;
        this.dateEdit[this.turmaAtividadeItems.indexOf(item)] = false;
        this.dateEdit = [...this.dateEdit];
    }
    async onChangeidiomaSelected(val, oldVal) {
        if (this.rodandoMounted) {
            return;
        }
        if (val.length === 0) {
            this.livroOptions = [];
            this.professorOptions = [];
            this.professorSelected = null;
            this.cursoSelected = [];
            this.livroSelected = [];
            return;
        }
        this.professorOptions = await userStore.getProfessoresByIdioma(val[0]);
        if (val[0] !== oldVal[0]) {
            this.livroOptions = [];
            // this.professorOptions = [];
            this.professorSelected = null;
            this.cursoSelected = [];
            this.livroSelected = [];
            return;
        }
    }
    async onChangeCurso(val, oldVal) {
        if (this.rodandoMounted) {
            return;
        }
        if (val.length === 0) {
            this.livroOptions = [];
            // this.professorOptions = [];
            this.professorSelected = null;
            this.livroSelected = [];
        }
        if (val[0] !== oldVal[0]) {
            this.livroOptions = [];
            // this.professorOptions = [];
            this.professorSelected = null;
            this.livroSelected = [];
        }
        if (val.length > 1) {
            const curso = val[1];
            this.livroOptions = _.filter(livroStore.livros, ['curso_id', curso.id]);
            this.livroOptions = _.orderBy(this.livroOptions, ['nome', 'edicao']);
        }
    }
    async onChangeLivro(val, oldVal) {
        if (this.rodandoMounted) {
            return;
        }
        if (val.length === 0) {
            // this.professorOptions = [];
            this.professorSelected = null;
            this.cursoModalidadeSelected = [];
            this.livroAtividadesOptions = [];
            return;
        }
        if (val[0] !== oldVal[0]) {
            // this.professorOptions = [];
            this.professorSelected = null;
            this.cursoModalidadeSelected = [];
            this.livroAtividadesOptions = [];
            this.livroAtividadesOptions = await atividadeStore.getAtividades(this.livroSelected[1].id);
        }
        this.entityObject.livro_id = this.livroSelected[1].id;
    }
    async onChangeCursoModalidadeSelected(val, oldVal) {
        if (this.rodandoMounted) {
            return;
        }
        if (val) {
            if (val.length > 0) {
                this.$nextTick(() => {
                    this.previsao_inicio = null;
                });
                this.previsao_termino = null;
                this.turmaAtividadeItems = [];
                this.entityObject.quantidade_aula_semana = val[1].dia_por_semana;
                this.entityObject.quantidade_aula_por_dia = val[1].aula_por_dia;
                this.cronograma_horario = [];
                for (let i = 0; i < this.entityObject.quantidade_aula_semana; i++) {
                    this.cronograma_horario.push({
                        dia_semana: null,
                        horario: '',
                        dia_semana_index: null,
                    });
                }
            }
        }
    }
    async onChangePrevisaoInicio(val) {
        if (this.rodandoMounted) {
            return;
        }
        if (val) {
            if (val.length === 10) {
                let d = this.previsao_inicio.split('-');
                d = d[1] + '/' + d[2] + '/' + d[0];
                const data = new Date(d);
                const n = data.getDay();
                const obj = {
                    // @ts-ignore
                    dia_semana: this.diasDaSemana()[n],
                    dia_semana_index: n,
                };
                this.cronograma_horario[0] = { ...this.cronograma_horario[0], ...obj };
            }
        }
    }
    async submit(retornarTelaAnterior = true) {
        if (this.turmaAtividadeItems.length <= 0) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: ['A turma precisa ter um planejamento'],
            });
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.cronograma_horario = this.cronograma_horario;
        this.entityObject.cronograma_conteudo = this.turmaAtividadeItems;
        // this.entityObject.modalidade_id = this.entityObject.modalidade_id.id;
        // colocar horario de início
        this.entityObject.previsao_inicio = this.previsao_inicio;
        if (this.previsao_termino) {
            this.entityObject.previsao_termino = this.previsao_termino;
        }
        if (this.id) {
            const promise1 = turmaStore.updateTurma(this.entityObject);
            const promise2 = turmaStore.updateCronograma({
                data: this.turmaAtividadeItems,
                id: this.id,
            });
            await Promise.all([promise1, promise2]);
        }
        else {
            this.creating = true;
            // @ts-ignore
            this.entityObject.modalidade_id = this.cursoModalidadeSelected[1].id;
            this.entityObject.status_id = 1;
            const result = await turmaStore.createTurma(this.entityObject);
            this.id = result;
            this.creating = false;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        if (retornarTelaAnterior) {
            await this.$router.push({ name: 'main-turmas' });
        }
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-company-companies-edit', params: { id } });
    }
    nextWeekdayDate(date, day_in_week) {
        const ret = new Date(date || new Date());
        ret.setUTCHours(4);
        ret.setDate(ret.getDate() + 1);
        ret.setDate(ret.getDate() + ((day_in_week - ret.getDay() + 7) % 7));
        const d = ret;
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        const dataFormatada = [year, month, day].join('-');
        if (this.getFeriados[dataFormatada]) {
            return this.nextWeekdayDate(ret, day_in_week);
        }
        else {
            return ret;
        }
    }
    get getFeriados() {
        return _.groupBy(feriadoStore.feriados, 'data');
    }
    async removeAula(idx) {
        this.entityObject.quantidade_aula_semana--;
        this.cronograma_horario.splice(idx, 1);
    }
    async ajustaCronograma(index_inicio_ajuste = 0) {
        const isValid = await this.$refs.previsaoInicioObserver.validate();
        if (!isValid) {
            return;
        }
        const cronograma = await atividadeStore.getAtividades(this.livroSelected[1].id);
        const cronogramaOld = this.turmaAtividadeItems;
        let data = this.previsao_inicio;
        if (index_inicio_ajuste !== 0) {
            data = this.turmaAtividadeItems[index_inicio_ajuste].data_atividade;
        }
        const days = _.map(
        // @ts-ignore
        this.cronograma_horario, 'dia_semana_index');
        let dayIndex = 0;
        if (days.length > 1) {
            dayIndex = 1;
        }
        let aulaAtual = 1;
        // Onde a magica acontece
        this.turmaAtividadeItems = cronograma.map((item, index) => {
            if (index < index_inicio_ajuste) {
                return this.turmaAtividadeItems[index];
            }
            const newData = data;
            if (cronogramaOld) {
                if (cronogramaOld.length > 0) {
                    if (cronogramaOld[index]) {
                        if (!cronogramaOld[index].sera_ministrada) {
                            return {
                                etapa: item.etapa,
                                obrigatoria: item.obrigatoria,
                                sera_ministrada: false,
                                atividade_prevista: item.descricao,
                                atividade_prevista_id: item.id,
                                atividade: item.descricao,
                                atividade_id: item.id,
                                data_atividade: null,
                                hora_inicio_atividade: null,
                                atividade_avaliacao: item.avaliacao,
                                atividade_homework: item.homework,
                                atividade_revisao: item.revisao,
                            };
                        }
                    }
                }
            }
            let indice = new Date(newData).getDay() + 1;
            if (indice === 7) {
                indice = 0;
            }
            const horario_inicio_atividade = _.find(this.cronograma_horario, (ch) => ch.dia_semana_index == indice).horario;
            let complementoMinutos = ':00';
            if (horario_inicio_atividade.length > 5) {
                complementoMinutos = '';
            }
            const horaInicioAtividadeSplit = horario_inicio_atividade.split(':');
            const object = {
                obrigatoria: item.obrigatoria,
                etapa: item.etapa,
                sera_ministrada: true,
                atividade_prevista: item.descricao,
                atividade_prevista_id: item.id,
                atividade: item.descricao,
                atividade_id: item.id,
                data_atividade: newData,
                atividade_avaliacao: item.avaliacao,
                atividade_homework: item.homework,
                atividade_revisao: item.revisao,
                hora_inicio_atividade: index % this.entityObject.quantidade_aula_por_dia === 0
                    ? horario_inicio_atividade + complementoMinutos
                    : `${+horaInicioAtividadeSplit[0] +
                        (index % this.entityObject.quantidade_aula_por_dia)}:${horaInicioAtividadeSplit[1]}` + complementoMinutos,
            };
            if (aulaAtual / this.entityObject.quantidade_aula_por_dia < 1) {
                aulaAtual = aulaAtual + 1;
            }
            else {
                aulaAtual = 1;
                const nextDay = this.nextWeekdayDate(data, days[dayIndex]);
                data = nextDay.toISOString();
                data = data.split('T')[0];
                if (dayIndex < days.length - 1) {
                    dayIndex++;
                }
                else {
                    dayIndex = 0;
                }
            }
            if (index === cronograma.length - 1) {
                this.previsao_termino = newData;
                this.entityObject.previsao_termino = this.previsao_termino;
            }
            return object;
        });
        if (this.turmaAtividadeItems) {
            const datas = { data_inicio: '', data_fim: '' };
            datas.data_inicio = this.previsao_inicio;
            datas.data_fim = this.previsao_termino;
            this.periodoFeriados = await feriadoStore.getFeriadosPeriodo(datas);
        }
        // setar a data de previsao de término
        // this.loadingDataTable = false;
    }
    onChangeDiaAula(event, index) {
        if (this.rodandoMounted) {
            return;
        }
        this.cronograma_horario[index - 1].dia_semana_index =
            // @ts-ignore
            this.diasDaSemana().indexOf(event);
        const obj = {
            // @ts-ignore
            dia_semana: 
            // @ts-ignore
            this.diasDaSemana()[this.cronograma_horario[index - 1].dia_semana_index],
            // @ts-ignore
            dia_semana_index: this.cronograma_horario[index - 1].dia_semana_index,
            horario: this.cronograma_horario[index - 1].horario,
        };
        this.cronograma_horario[index - 1] = obj;
    }
    async mounted() {
        this.rodandoMounted = true;
        await cursoStore.getCursos();
        await salaStore.getSalas();
        await feriadoStore.getFeriados();
        await livroStore.getLivros();
        if (this.$router.currentRoute.params.id) {
            this.id = parseInt(this.$router.currentRoute.params.id, 0);
            this.entityObject = await turmaStore.getTurma(parseInt(this.$router.currentRoute.params.id, 0));
            this.alunosTurma = await turmaStore.getTurmaAlunosSimplificado(this.id);
            this.idiomaSelected = [this.entityObject.livro.curso.idioma];
            this.cursoSelected = [this.entityObject.livro.curso.nome];
            this.livroOptions = _.filter(livroStore.livros, [
                'curso_id',
                this.entityObject.livro.curso.id,
            ]);
            this.livroSelected = [
                this.entityObject.livro.nome + ' - ' + this.entityObject.livro.edicao,
                { id: this.entityObject.livro.id },
            ];
            this.livroAtividadesOptions = await atividadeStore.getAtividades(this.entityObject.livro.id);
            this.cursoModalidadeSelected = [
                // @ts-ignore
                this.cursoModalidadeOptions()[this.entityObject.modalidade_id - 1].nome,
            ];
            this.professorOptions = await userStore.getProfessoresByIdioma(this.entityObject.livro.curso.idioma);
            if (this.entityObject.professor) {
                this.professorSelected = [this.entityObject.professor.id];
            }
            // this.cursoModalidadeSelected = [
            //   // @ts-ignore
            //   this.cursoModalidadeOptions()[this.entityObject.modalidade_id - 1].nome,
            // ];
            this.curso_id = this.entityObject.livro.curso_id;
            this.curso = _.find(this.cursoOptions, (item) => item.id === this.curso_id);
            this.cronograma_horario = this.entityObject.cronograma_horario;
            // this.cronograma_conteudo = this.entityObject.cronograma_horario;
            this.previsao_inicio = this.entityObject.previsao_inicio.substr(0, 10);
            if (this.entityObject.previsao_termino) {
                this.previsao_termino = this.entityObject.previsao_termino.substr(0, 10);
            }
            this.turmaAtividadeItems = await turmaStore.getCronograma(this.id);
            if (this.turmaAtividadeItems) {
                const datas = { data_inicio: '', data_fim: '' };
                datas.data_inicio = this.previsao_inicio;
                datas.data_fim = this.previsao_termino;
                this.periodoFeriados = await feriadoStore.getFeriadosPeriodo(datas);
            }
            else {
                this.periodoFeriados = await feriadoStore.getFeriados();
            }
            this.rodandoMounted = false;
        }
        else {
            this.entityObject = newTurma();
        }
        this.isMobileTurma = this.$vuetify.breakpoint.mobile;
        if (this.$vuetify.breakpoint.mobile && window.innerWidth < 768) {
            this.isMobileTurma = this.$vuetify.breakpoint.mobile;
        }
        this.rodandoMounted = false;
    }
};
__decorate([
    Watch('turmaAtividadeItems')
], Create.prototype, "onChangeTurmaAtividadeItems", null);
__decorate([
    Watch('idiomaSelected')
], Create.prototype, "onChangeidiomaSelected", null);
__decorate([
    Watch('cursoSelected')
], Create.prototype, "onChangeCurso", null);
__decorate([
    Watch('livroSelected')
], Create.prototype, "onChangeLivro", null);
__decorate([
    Watch('cursoModalidadeSelected')
], Create.prototype, "onChangeCursoModalidadeSelected", null);
__decorate([
    Watch('previsao_inicio', { immediate: true })
], Create.prototype, "onChangePrevisaoInicio", null);
Create = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
            FichaFrequenciaComponent,
            GestaoComponent,
            JaasMeet,
            LogShowComponent,
            RequestPermissionComponent,
            SaveButton,
            TurmaAlunoAtividadeLogComponent,
            TurmaCancelarComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], Create);
export default Create;
